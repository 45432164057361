import Login from "./Login"

const HalamanLogin = () => {
  return (
    <div>
      <Login />
    </div>
  )
}

export default HalamanLogin