import Footer from "../../components/Footer"
import Berkacamain from "./Berkacamain"
import Navbar from "../../components/Header/Navbar"

const Berkacamainpage = () => {
  return (
    <div>
      <Navbar />
      <Berkacamain />
      <Footer />
      {/* <Glosarium/> */}
    </div>
  )
}

export default Berkacamainpage