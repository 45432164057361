import Forgot from "./Forgot"

const Forgotpage = () => {
  return (
    <div>
      <Forgot />
    </div>
  )
}

export default Forgotpage