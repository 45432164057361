import Register from "./Register"

const HalamanLogin = () => {
  return (
    <div>
      <Register />
    </div>
  )
}

export default HalamanLogin