import Ubahpas from "./Ubahpas"

const Ubahpasspage = () => {
  return (
    <div>
      <Ubahpas />
    </div>
  )
}

export default Ubahpasspage