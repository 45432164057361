import Footer from "../../components/Footer"
import Jelajahmain from "./Jelajahmain alternative"
import Navbar from "../../components/Header/Navbar"
import Glosarium from "../../components/Glosarium"

const Jelajahmainpage = () => {
  return (
    <div>
      <Navbar />
      <Jelajahmain />
      <Footer />
      {/* <Glosarium/> */}
    </div>
  )
}

export default Jelajahmainpage