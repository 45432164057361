import Arsip from "./arsip"
import Footer from "../../components/Footer"
import Navbar from "../../components/Header/Navbar"

const Jelajahpage = () => {
  return (
    <div>
      <Navbar />
      <Arsip />
      <Footer />
    </div>
  )
}

export default Jelajahpage